import React from 'react';
import Slider from "react-slick";
import banner2 from "../Assets/banner2.png";
import banner3 from "../Assets/banner3.png";
import "./Banner.css"

function Banner() {
  // slider js 
  var bannerslider = {
    dots: true,
    infinite: true,
    slidesToShow: 1.02,  
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  // slider js 
  return (
    <div className='banner'>
   <div className='container'>
   <Slider {...bannerslider}>
   <div>
      <img src={banner3} height="100%" width="100%" className="slider1" />
      </div>
      <div>
  <img src={banner2} height="100%" width="100%" className="slider1" />
      </div>
     
      {/* <div>
      <img src={banner2} height="100%" width="100%" className="slider1" />
      </div> */}
  
    </Slider>
   </div>
    </div>
  )
}

export default Banner