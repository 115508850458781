import React, { useState } from 'react';
import confetti from 'canvas-confetti';
import './Success.css'; // External CSS file for styling

const Success = ({ onClick }) => {
  const [buttonState, setButtonState] = useState('default'); // 'default', 'loading', 'success'
  const [buttonText, setButtonText] = useState('Get Gifzi Plus');
  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

  const startConfetti = () => {
    // Immediately set the button to success state
    setButtonState('success');
    setButtonText('Card Created ');
    setShowAlert(true); // Show the alert

    const button = document.getElementById('button-conf');
    const rect = button.getBoundingClientRect();
    const center = {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
    const origin = {
      x: center.x / window.innerWidth,
      y: center.y / window.innerHeight,
    };

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, opts, {
          particleCount: Math.floor(100 * particleRatio),
          origin,
          colors: ['#4C093F', '#FFCC00', '#34C759'],
        })
      );
    }

    onClick(); // Trigger any additional functionality (e.g., API call)

    // Trigger confetti immediately
    fire(0.25, { spread: 26, startVelocity: 10 });
    fire(0.2, { spread: 60, startVelocity: 20 });
    fire(0.35, { spread: 100, startVelocity: 15 });
    fire(0.1, { spread: 120, startVelocity: 10 });
    fire(0.1, { spread: 120, startVelocity: 20 });

    // Hide the alert and reset the button after 3 seconds
    setTimeout(() => {
      setShowAlert(false);
      setButtonState('default');
      setButtonText('Get Gifzi Plus');
    }, 3000);
  };

  return (
    <>
      {/* {showAlert && (
        <div className="alert">
          ✨ Gift Plus card is created successfully ✨
        </div>
      )} */}
      <button
        id="button-conf"
        className={`common-btn ${buttonState}`}
        onClick={startConfetti}
        disabled={buttonState === 'loading'} // Disable button during loading
      >
        <span id="text" className="text">
          {buttonText}
        </span>
      </button>
    </>
  );
};

export default Success;
