import React, { useState } from "react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";

function SomeoneElse({ onGiftChange, onGiftDetailChange }) {
  const [isChecked, setIsChecked] = useState(false);
  const [giftDetails, setGiftDetails] = useState({
    firstname: "",
    email: "",
    telephone: "",
    gift_message: "",
  });

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onGiftChange(checked);

    if (!checked) {
      setGiftDetails({
        firstname: "",
        email: "",
        telephone: "",
        gift_message: "",
      });
      onGiftDetailChange({
        firstname: "",
        email: "",
        telephone: "",
        gift_message: "",
      });
      // Remove stored data if unchecked
      localStorage.removeItem("giftDetails");
    } else {
      // Store current gift details in local storage when checked
      localStorage.setItem("giftDetails", JSON.stringify(giftDetails));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedDetails = { ...giftDetails, [name]: value };
    setGiftDetails(updatedDetails);
    onGiftDetailChange(updatedDetails);

    // Update local storage with the latest details
    if (isChecked) {
      localStorage.setItem("giftDetails", JSON.stringify(updatedDetails));
    }
  };

  return (
    <>
      <div className="someelse d-flex-center border">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <p className="f-18 f-weight-600 text-o">ORDER FOR SOMEONE ELSE</p>
      </div>

      {isChecked && (
        <div className={`bg-fb someone-detail show-detail`}>
          {/* Input Fields */}
          <Input
            placeholdertext="Enter recipient's name"
            labeltext="Name"
            fortext="name"
            typetext="text"
            name="firstname"
            value={giftDetails.firstname}
            onChange={handleInputChange}
          />
          <Input
            placeholdertext="Enter recipient's email"
            labeltext="Email"
            fortext="email"
            typetext="email"
            name="email"
            value={giftDetails.email}
            onChange={handleInputChange}
          />
          <Input
            placeholdertext="Enter recipient's phone number"
            labeltext="Phone Number"
            fortext="phone"
            typetext="tel"
            name="telephone"
            value={giftDetails.telephone}
            onChange={handleInputChange}
          />
          <Input
            placeholdertext="Enter a gift message"
            labeltext="Message"
            fortext="message"
            typetext="text"
            name="gift_message"
            value={giftDetails.gift_message}
            onChange={handleInputChange}
          />

          {/* <div className="col-md-12 btn-flex-center d-flex gap-2">
            <Commonbtn Commonbtntext="Save" />
            <button
              className="Commonredbtn"
              onClick={() => setIsChecked(false)}
            >
              Cancel
            </button>
          </div> */}
        </div>
      )}
    </>
  );
}

export default SomeoneElse;
