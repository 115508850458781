// import React, { useState } from "react";
// import icon from "../../Assets/Authentication/icon.svg";
// import card from "../../Assets/gifzicard.svg";
// import "./Form.css";
// import Input from "../../Input/Input";
// import Commonbtn from "../../Commonbtn/Commonbtn";
// import Logo from "../../Logo/Logo";
// import { Link, useNavigate } from "react-router-dom";
// import Password from "../../Input/Password"; // Reused Password component
// import Alert from "../../Alert/Alert"; // Import Alert component
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// function Form() {
//   const [formData, setFormData] = useState({
//     first_name: "",
//     last_name: "",
//     mobile: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     role: "",
//     salutation: "Mr.",
//   });
//   const [alertMessage, setAlertMessage] = useState(""); // State for alert message
//   const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
//   const [error, setError] = useState(null); // To handle error messages
//   const [firstName, setFirstName] = useState(""); // State to store the first name after registration
//   const navigate = useNavigate(); // Hook to programmatically navigate
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [confirmPasswordError, setConfirmPasswordError] = useState('');

//   // Email validation function
//   const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
//   const togglePasswordVisibility = () => {
//     setIsPasswordVisible(!isPasswordVisible);
//   };

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value, type } = e.target;

//     if (type === "number" && name === "mobile") {
//       if (/^\d*$/.test(value) && value.length <= 10) {
//         setFormData((prevData) => ({
//           ...prevData,
//           [name]: value,
//         }));
//       }
//     } else {
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError(null);

//     if (formData.password !== formData.confirmPassword) {
//       setError("Passwords do not match!");
//       return;
//     }

//     if (!validateEmail(formData.email)) {
//       setError("Please enter a valid email address.");
//       return;
//     }

//     if (formData.mobile.length !== 10) {
//       setError("Mobile number must be exactly 10 digits.");
//       return;
//     }
//     const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/register/request`;

//     try {
//       // Using a CORS proxy for development
//       const response = await fetch(baseUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           first_name: formData.first_name,
//           last_name: formData.last_name,
//           mobile: formData.mobile,
//           email: formData.email,
//           password: formData.password,
//           user_type: formData.user_type,
//           salutation: formData.salutation,
//         }),
//       });

//       const result = await response.json();

//       if (response.ok) {
//         // Show alert for OTP
//         setAlertMessage("OTP has been sent to your mobile number!");
//         setShowAlert(true);

//         // Hide the alert after 5 seconds
//         setTimeout(() => {
//           setShowAlert(false);
//         }, 5000);

//         localStorage.setItem("user-info", JSON.stringify(result));
//         localStorage.setItem("firstName", result.first_name);
//         navigate("/verification", {
//           state: {
//             mobile: formData.mobile,
//             firstName: result.first_name,
//             otp: result.data.otp,
//           },
//         });
//         // navigate("/verification");
//       } else {
//         setError(result.message || "Registration failed");
//       }
//     } catch (err) {
//       setError("Something went wrong, please try again.");
//     }
//   };

//   const handleInputNameChange = (e) => {
//     const { name, value } = e.target;

//     // Allow only alphabetic characters and spaces
//     const lettersOnly = /^[A-Za-z\s]*$/;

//     if (lettersOnly.test(value)) {
//       setFormData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     }
//   };
 
//   const handleInputPasswordChange = (e) => {
//     const { name, value } = e.target;

//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));

//     const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[\S]{8,16}$/;

//     if (value.length > 0 && !passwordPattern.test(value)) {
//       setError(
//         "Password must be 8-16 characters long and contain both letters and numbers."
//       );
//     } else {
//       setError(""); 
//     }
//   };

//   const handleInputConfirmPasswordChange = (e) => {
//     const { value } = e.target;

//     setFormData((prevState) => ({
//       ...prevState,
//       confirmPassword: value,
//     }));

//     if (value !== formData.password) {
//       setConfirmPasswordError("Passwords do not match.");
//     } else {
//       setConfirmPasswordError("");
//     }
//   };

//   return (
//     <section className="authentication register">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-7">
//             <div className="form-left">
//               <form id="survey-form" onSubmit={handleSubmit}>
//                 <div className="row">
//                   {showAlert && <Alert title={alertMessage} />}
//                   <div className="form-head col-md-12">
//                     <div className="logo-for desk-none">
//                       <Logo />
//                     </div>

//                     <h1 className="f-40 mb-2">Register</h1>
//                     <p className="f-18 f-weight-600">
//                       Already have an account?{" "}
//                       <Link
//                         to="/login"
//                         className="text-decoration-underline text-main"
//                       >
//                         Login now
//                       </Link>
//                     </p>
//                   </div>

//                   {/* <div className="col-md-12 role">
//                     <p className="f-16 f-weight-600 mb-2">
//                       Select your role here
//                     </p>
//                     <div className="d-flex">
//                       <div className="form-check1">
//                         <input
//                           type="radio"
//                           className="checkbox-round"
//                           name="user_type"
//                           id="user_type"
//                           value="2"
//                           onChange={handleInputChange}
//                         />
//                         <label className="form-check-label m-0" for="user_type">
//                           Customer
//                         </label>
//                       </div>
//                       <div className="form-check">
//                         <input
//                           type="radio"
//                           className="checkbox-round"
//                           id="user_type2"
//                           name="user_type"
//                           value="3"
//                           onChange={handleInputChange}
//                         />
//                         <label
//                           className="form-check-label m-0"
//                           for="user_type2"
//                         >
//                           Corporate user
//                         </label>
//                       </div>
//                     </div>
//                   </div> */}

//                   <div className="col-md-12">
//                     <Input
//                       placeholdertext="Enter your first name"
//                       labeltext="First Name"
//                       fortext="first_name"
//                       typetext="text"
//                       name="first_name"
//                       value={formData.first_name}
//                       onChange={handleInputNameChange}
//                       maxLength={50}
//                     />
//                   </div>

//                   <div className="col-md-12">
//                     <Input
//                       placeholdertext="Enter your last name"
//                       labeltext="Last Name"
//                       fortext="last_name"
//                       typetext="text"
//                       name="last_name"
//                       value={formData.last_name}
//                       onChange={handleInputNameChange}
//                       maxLength={50}
//                     />
//                   </div>

//                   <div className="col-md-12">
//                     <Input
//                       placeholdertext="Enter your mobile number"
//                       labeltext="Mobile Number"
//                       fortext="mobile"
//                       typetext="number"
//                       name="mobile"
//                       value={formData.mobile}
//                       onChange={handleInputChange}
//                       maxLength={10} // Restrict input to 10 digits
//                     />
//                   </div>

//                   <div className="col-md-12">
//                     <Input
//                       placeholdertext="Enter your email"
//                       labeltext="Email"
//                       fortext="email"
//                       typetext="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleInputChange}
//                     />
//                   </div>

//                   <div className="col-md-12">
//                     <div class="form-group position-relative">
//                       <label htmlFor="password">Password</label>
//                       <input
//                         type={isPasswordVisible ? "text" : "password"}
//                         id="password"
//                         name="password"
//                         class="form-control shadow-sm"
//                         value={formData.password}
//                         onChange={handleInputPasswordChange}
//                         placeholder="Enter your password"
//                         minLength={8} // Minimum length is 8 characters
//                         maxLength={16} // Maximum length is 16 characters
//                         required
//                       />
//                       <span
//                         className="password-eye"
//                         onClick={togglePasswordVisibility}
//                       >
//                         <FontAwesomeIcon
//                           icon={isPasswordVisible ? faEyeSlash : faEye}
//                         />
//                       </span>
//                     </div>

//                     {error && (
//                       <p className="error-message" style={{ color: "red" }}>
//                         {error}
//                       </p>
//                     )}
//                   </div>

//                   <div className="col-md-12">
//                     <Password
//                       placeholdertext1="Confirm your password"
//                       labeltext1="Confirm Password"
//                       fortext1="confirmPassword"
//                       name="confirmPassword"
//                       value={formData.confirmPassword}
//                       onChange={handleInputConfirmPasswordChange }
//                     />
//                   </div>

//                   {confirmPasswordError  && <div className="error-message">{confirmPasswordError }</div>}

//                   <div className="col-md-12 d-flex-center btn-form">
//                     <Commonbtn Commonbtntext="Sign up" />
//                   </div>
//                   <Link to="/">
//                     <div className="col-md-12 d-flex-center align-items-center gap-2 mt-3">
//                       <i className="arrow-right ">
//                         <svg
//                           width="15px"
//                           height="15px"
//                           viewBox="0 0 18 15"
//                           fill="none"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <g clipPath="url(#clip0_945_2412)">
//                             <path
//                               d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5"
//                               stroke="black"
//                               strokeWidth="2"
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                             />
//                           </g>
//                           <defs>
//                             <clipPath id="clip0_945_2412">
//                               <rect
//                                 width="18"
//                                 height="14"
//                                 fill="white"
//                                 transform="translate(0 0.5)"
//                               />
//                             </clipPath>
//                           </defs>
//                         </svg>
//                       </i>
//                       <p className="f-14 text-grey ">
//                         Back To{" "}
//                         <strong className="text-decoration-underline">
//                           {" "}
//                           Home
//                         </strong>
//                       </p>
//                     </div>
//                   </Link>
//                 </div>
//               </form>
//             </div>
//           </div>

//           <div className="col-md-5 form-wrap">
//             <div className="form-right-sec">
//               <div className="logo-for">
//                 <Logo />
//               </div>
//               <div className="form-right position-relative">
//                 <h3 className="f-30 f-weight-600 text-main mb-3">
//                   E-Gift Vouchers
//                 </h3>
//                 <p className="f-16">
//                   Use your gift card around the world with no hidden fees. Hold,
//                   transfer, and spend money.
//                 </p>
//                 <div className="card-des">
//                   <img src={card} alt="Gift card" className="img-fluid" />
//                 </div>
//               </div>
//               <div className="card-design shadow-lg rounded-2 row d-flex-center text-start p-2">
//                 <div className="icon col-md-4">
//                   <img src={icon} alt="Icon" className="img-fluid" />
//                 </div>
//                 <div className="right col-md-8">
//                   <span className="f-14">Gifzi</span>
//                   <p className="f-16 f-weight-600 text-lr">
//                     Effortless Gifting, Endless Possibilities.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Form;



import React, { useState } from "react";
import icon from "../../Assets/Authentication/icon.svg";
import card from "../../Assets/gifzicard.svg";
import "./Form.css";
import Input from "../../Input/Input";
import Commonbtn from "../../Commonbtn/Commonbtn";
import Logo from "../../Logo/Logo";
import { Link, useNavigate } from "react-router-dom";
import Password from "../../Input/Password"; // Reused Password component
import Alert from "../../Alert/Alert"; // Import Alert component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Form() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    salutation: "Mr.",
  });
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
  const [error, setError] = useState(null); // To handle error messages
  const [firstName, setFirstName] = useState(""); // State to store the first name after registration
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    user_type: "",
  });
  // Email validation function
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "number" && name === "mobile") {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setFieldErrors({}); // Reset field errors before submission
  
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
  
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address.");
      return;
    }
  
    if (formData.mobile.length !== 10) {
      setError("Mobile number must be exactly 10 digits.");
      return;
    }
  
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/register/request`;
  
    try {
      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: formData.first_name,
          last_name: formData.last_name,
          mobile: formData.mobile,
          email: formData.email,
          password: formData.password,
          user_type: formData.user_type,
          salutation: formData.salutation,
        }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        // Show alert for OTP
        setAlertMessage("OTP has been sent to your mobile number!");
        setShowAlert(true);

        // Hide the alert after 5 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);

        localStorage.setItem("user-info", JSON.stringify(result));
        localStorage.setItem("firstName", result.first_name);
        navigate("/verification", {
          state: {
            mobile: formData.mobile,
            firstName: result.first_name,
            otp: result.data.otp,
          },
        });
        // navigate("/verification");
      } else {
        if (result.errors) {
          const errors = {};
          result.errors.forEach((error) => {
            errors[error.path] = error.msg;
          });
          setFieldErrors(errors);
        } else {
          setError(result.message || "Registration failed");
        }
      }
    } catch (err) {
      setError("Something went wrong, please try again.");
    }
  };
  
  const handleInputNameChange = (e) => {
    const { name, value } = e.target;

    // Allow only alphabetic characters and spaces
    const lettersOnly = /^[A-Za-z\s]*$/;

    if (lettersOnly.test(value)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
 
  const handleInputPasswordChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[\S]{8,16}$/;

    if (value.length > 0 && !passwordPattern.test(value)) {
      setError(
        "Password must be 8-16 characters long and contain both letters and numbers."
      );
    } else {
      setError(""); 
    }
  };

  const handleInputConfirmPasswordChange = (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      confirmPassword: value,
    }));

    if (value !== formData.password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  return (
    <section className="authentication register">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="form-left">
              <form id="survey-form" onSubmit={handleSubmit}>
                <div className="row">
                  {showAlert && <Alert title={alertMessage} />}
                  <div className="form-head col-md-12">
                    <div className="logo-for desk-none">  <Logo />  </div>
                    <h1 className="f-40 mb-2">Register</h1>
                    <p className="f-18 f-weight-600">
                      Already have an account?{" "}
                      <Link  to="/login"  className="text-decoration-underline text-main" >
                        Login now
                      </Link>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your first name"
                      labeltext="First Name"
                      fortext="first_name"
                      typetext="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputNameChange}
                      maxLength={50}
                    />
                      {fieldErrors.first_name && (
                    <p className="error-message mb-2 " style={{ color: "red" }}>
                      {fieldErrors.first_name}
                    </p>
                  )}
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your last name"
                      labeltext="Last Name"
                      fortext="last_name"
                      typetext="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputNameChange}
                      maxLength={50}
                    />
                     {fieldErrors.last_name && (
                    <p className="error-message mb-2 " style={{ color: "red" }}>
                      {fieldErrors.last_name}
                    </p>
                  )}
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your mobile number"
                      labeltext="Mobile Number"
                      fortext="mobile"
                      typetext="number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      maxLength={10} // Restrict input to 10 digits
                    />
                    {fieldErrors.mobile && (
                    <p className="error-message mb-2 " style={{ color: "red" }}>
                      {fieldErrors.mobile}
                    </p>
                  )}
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your email"
                      labeltext="Email"
                      fortext="email"
                      typetext="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <div class="form-group position-relative">
                      <label htmlFor="password">Password</label>
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        class="form-control shadow-sm"
                        value={formData.password}
                        onChange={handleInputPasswordChange}
                        placeholder="Enter your password"
                        minLength={8} // Minimum length is 8 characters
                        maxLength={16} // Maximum length is 16 characters
                        required
                      />
                      <span
                        className="password-eye"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>

                    {error && (
                      <p className="error-message" style={{ color: "red" }}>
                        {error}
                      </p>
                    )}
                  </div>

                  <div className="col-md-12">
                    <Password
                      placeholdertext1="Confirm your password"
                      labeltext1="Confirm Password"
                      fortext1="confirmPassword"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputConfirmPasswordChange }
                    />
                  </div>

                  {confirmPasswordError  && <div className="error-message">{confirmPasswordError }</div>}

                  <div className="col-md-12 d-flex-center btn-form">
                    <Commonbtn Commonbtntext="Sign up" />
                  </div>
                  <Link to="/">
                    <div className="col-md-12 d-flex-center align-items-center gap-2 mt-3">
                      <i className="arrow-right ">
                        <svg width="15px" height="15px"viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <g clipPath="url(#clip0_945_2412)"> <path d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5" stroke="black"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </g>
                          <defs>
                            <clipPath id="clip0_945_2412">
                              <rect width="18"height="14"fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      <p className="f-14 text-grey ">
                        Back To{" "}
                        <strong className="text-decoration-underline">
                          {" "}
                          Home
                        </strong>
                      </p>
                    </div>
                  </Link>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-5 form-wrap">
            <div className="form-right-sec">
              <div className="logo-for">
                <Logo />
              </div>
              <div className="form-right position-relative">
                <h3 className="f-30 f-weight-600 text-main mb-3">
                  E-Gift Vouchers
                </h3>
                <p className="f-16">
                  Use your gift card around the world with no hidden fees. Hold,
                  transfer, and spend money.
                </p>
                <div className="card-des">
                  <img src={card} alt="Gift card" className="img-fluid" />
                </div>
              </div>
              <div className="card-design shadow-lg rounded-2 row d-flex-center text-start p-2">
                <div className="icon col-md-4">
                  <img src={icon} alt="Icon" className="img-fluid" />
                </div>
                <div className="right col-md-8">
                  <span className="f-14">Gifzi</span>
                  <p className="f-16 f-weight-600 text-lr">
                    Effortless Gifting, Endless Possibilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Form;
