// import React from 'react'
// import hm from '../Assets/Icon/hm.png'
// import './Offers.css'
// import Strip from './Strip'

// const offerdata = [
//     {
//         image: hm,
//         title: 'Shop in Style!',
//         description: 'Get heavy discount on the H&M app',
//         terms: '*T&C apply'
//     },
//     {
//         image: hm,
//         title: 'Shop in Style!',
//         description: 'Get heavy discount on the H&M app',
//         terms: '*T&C apply'
//     },
//     {
//         image: hm,
//         title: 'Shop in Style!',
//         description: 'Get heavy discount on the H&M app',
//         terms: '*T&C apply'
//     },
//     {
//         image: hm,
//         title: 'Shop in Style!',
//         description: 'Get heavy discount on the H&M app',
//         terms: '*T&C apply'
//     },
//     {
//         image: hm,
//         title: 'Shop in Style!',
//         description: 'Get heavy discount on the H&M app',
//         terms: '*T&C apply'
//     },
//     {
//         image: hm,
//         title: 'Shop in Style!',
//         description: 'Get heavy discount on the H&M app',
//         terms: '*T&C apply'
//     },
// ]
// function Offers() {
//   return (
//     <div className='offers p-60'>   
//    <div className='container'>
//     <h1 className='f-40 text-center mb-3'>Offers</h1>
//     <p className='f-20 grey text-center'>Special offers handpicked for you</p>
//     <div className='row offer-item'>
//     {
//         offerdata.map((item) => (
//             <div key={item.title} className='col-md-3'>
//                 <div className='offer-card shadow-sm rounded-3 bg-white'>
//                     <img src={item.image} alt={item.title} height="30px" width="30px" className='mb-1' />
//                     <h3 className='f-20 mb-1' >{item.title}</h3>
//                     <p className='f-18 text-muted mb-1'>{item.description}</p>
//                     <p className='f-12 text-muted'>{item.terms}</p>
//                     <button>Buy Now</button>
//                 </div>
//             </div>
//         ))
 
//     }
 

//    </div>
//    <Strip/>
//    </div>
//   </div>
//   )
// }

// export default Offers


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './Offers.css';
// import Strip from './Strip';

// const Offers = () => {
//   const [offerData, setOfferData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [offset, setOffset] = useState(0);
//   const limit = 10; // Fixed limit per request

//   useEffect(() => {
//     fetchOffers(); // Initial fetch

//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
//         fetchOffers(); // Fetch more offers on scroll
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [offset]);

//   const fetchOffers = async () => {
//     if (loading) return; // Prevent duplicate requests

//     setLoading(true);
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}home/offers?offset=${offset}&limit=${limit}`, {
//         headers: {
//           Authorization: localStorage.getItem("authToken"),
//         },
//       });
//       const newOffers = response?.data?.data?.offers
//       ;
//       console.log('newOffers',newOffers);

//       if (newOffers.length > 0) {
//         setOfferData(prevOffers => [...prevOffers, ...newOffers]);
//         setOffset(prevOffset => prevOffset + limit); // Increment offset for next batch
//       }
//     } catch (err) {
//       setError('Failed to load offers');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className='offers p-60'>
//       <div className='container'>
//         <h1 className='f-40 text-center mb-3'>Offers</h1>
//         <p className='f-20 grey text-center'>Special offers handpicked for you</p>
//         {error ? (
//           <p>{error}</p>
//         ) : (
//           <div className='row offer-item'>
//             {offerData.map((item) => (
//               <div key={item.id} className='col-md-3'>
//                 <div className='offer-card shadow-sm rounded-3 bg-white'>
//                   <h3 className='f-20 mb-1'>{item.sku}</h3>
//                   <p className='f-18 text-muted mb-1' dangerouslySetInnerHTML={{ __html: item.discount_desc }} />
//                   <p className='f-12 text-muted'>Coupon Code: {item.coupon_code}</p>
//                   <p className='f-12 text-muted'>
//                     Discount: {item.discount_type === 'BY_FIXED' ? `₹${item.discount_amount}` : `${item.discount_amount}%`}
//                   </p>
//                   <p className='f-12 text-muted'>
//                     Valid: {item.start_date} - {item.end_date}
//                   </p>
//                   <button>Use Coupon</button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//         {loading && <p>Loading more offers...</p>}
//         <Strip />
//       </div>
//     </div>
//   );
// };

// export default Offers;





import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Offers.css';
import Strip from './Strip';
import { Link, useNavigate } from 'react-router-dom';

const Offers = () => {
  const [offerData, setOfferData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if more offers are available
  const limit = 10; // Number of offers per request
  const navigate = useNavigate();

  // Fetch offers function
  const fetchOffers = async () => {
    if (loading || !hasMore) return; // Prevent duplicate requests and stop if no more data

    setLoading(true);
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}home/offers?offset=0&limit=10`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      
      if (response.status === 401) {
        navigate("/login"); // Redirect to login on unauthorized access
      } else if (response.data && response.data.data && response.data.data.offers) {
        const newOffers = response.data.data.offers;
        setOfferData(newOffers);
     
      } else {
        setErrorMessage('No more offers available.');
        setHasMore(false); // No more offers to load
      }
    } catch (error) {
      setErrorMessage('Error fetching offers. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch offers on component mount
  useEffect(() => {
    fetchOffers();
  }, []);



  return (
    <div className='offers p-60'>
      <div className='container'>
        <h1 className='f-40 text-center mb-3'>Offers</h1>
        <p className='f-20 grey text-center'>Special offers handpicked for you</p>
        {errorMessage ? (
          <p className='text-center text-danger'>{errorMessage}</p>
        ) : (
          <div className='row offer-item '>
            {offerData.map((item) => (
              <div key={item.id} className='gift-card col-md-3'>
                <Link to={`/offerdetail/${item.id}`}>
                <div className='offer-card  shadow rounded-3 bg-white'>
                    <img src={item.thumbnail } className='product-image' />
                  <h3 className='f-20 mb-1'>{item.name}</h3>
               
                  <strong className='fs-6 '>
                    Discount : {item.discount_type === 'BY_FIXED' ? `₹${item.discount_amount}` : `${item.discount_max}%`}
                  </strong>
                  <p className='f-12 badge-success text-success fs-6 mt-3'>
                    Valid: {item.start_date} to {item.end_date}
                  </p>
                
                </div>
                </Link>
              </div>
            ))}
          </div>
        )}
        {loading && <p>Loading more offers...</p>}
        <Strip />
      </div>
    </div>
  );
};

export default Offers;
