import React, { useState, useEffect, createContext } from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home";
import { FCMProvider } from "./context/FCMContext";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import About from "./Pages/About";
import HowItWorks from "./Pages/HowItWorks";
import Giftcard from "./Pages/Giftcard";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Verification from "./Pages/Verification";
import Forgotpassword from "./Pages/Forgotpassword";
import Newpassword from "./Pages/Newpassword";
//import Notification from './Pages/Notification';
import NotificationPage from "./Pages/Notification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Profile from "./Pages/Profile";
import OrderHistory from "./Pages/OrderHistory";
import WriteEarn from "./Pages/WriteEarn";
import AllOffers from "./Pages/AllOffers";
import Mybalance from "./Pages/Mybalance";
import Faq from "./Pages/Faq";
import Cart from "./Pages/Cart";
import AOS from "aos";
import "aos/dist/aos.css";
import Preloader from "./Components/Preloader/Preloader"; // Add the Preloader
import "./Components/Responsive.css";
import PrivacyPolicy from "./Components/TermsAndConditions/PrivacyPolicy";
import MyOrderDetail from "./Components/Myorder/MyOrderDetail";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import Review from "./Components/Review/Review";
import MyReview from "./Pages/MyReview";
import Refund from "./Components/TermsAndConditions/Refund";
import GiftPlus from "./Pages/GiftPlus";
import LoginOtp from "./Components/Authentication/Otp/LoginOtp";
import ForgetpasswordOtp from "./Components/Authentication/Otp/Forgetpasswordotp";
import ProductDetail from "./Components/ProductDetail/ProductDetail";
import Viewall from "./Components/CategoryViewAll/Viewall";
import CategoryDetail from "./Components/Category/CategoryDetail";
import CategoryList from "./Components/Category/CategoryList";
import ProductByCategory from "./Pages/ProductByCategory";
import Mycard from "./Components/Mycard/Mycard";
import AddPlusCard from "./Components/AddPlusCard/AddPlusCard";
import RechargeGifziPlus from "./Pages/RechargeGifziPlus";
import { isAuthenticated } from "./auth";
import AllBlog from "./Pages/AllBlog";
import { CartProvider } from "./context/CartContext";
import { messaging, getToken, onMessage } from "./firebase";

import Successfull_Page from "./Components/AlertPage/Successfull_Page";
import PayUComponent from "./Components/PayU/PayUComponent";
import PayU from "./Pages/PayU";
import Failed_Page from "./Components/AlertPage/Failed_Page";
import WriteReview from "./Components/WriteReview/WriteReview";
import BlogDetail from "./Components/Blog/BlogDetails/BlogDetail";
import GetPost from "./Components/Write&earn/GetPost";
import OfferDetail from "./Components/OfferDetail/OfferDetail";

function App() {
  const [loading, setLoading] = useState(true); // Add loading state
  const [cartCount, setCartCount] = useState(0); // State for cart item count

  // AOS Initialization
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Simulate a loading process
  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Turn off preloader after 2 seconds
    }, 2000);
  }, []);

  // count
  useEffect(() => {
    const token = localStorage.getItem("cartToken");
    if (token) {
      const storedCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      const cartItemsForToken = storedCartItems.filter(
        (item) => item.token === token
      );
      setCartCount(
        cartItemsForToken.reduce((total, item) => total + item.qty, 0)
      );
    }
  }, []);

  // Firebase Push Notifications
  // useEffect(() => {
  //   // Request permission to send notifications
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       // Get FCM token
  //       const VAPID_KEY = 'BL2hCDlpJVE3zxMxZaZY-XlKNDZgN3pqF_iQ3Tz8US7p0KEv_o0hTAuD8IbycQt6o7-vaAbYBTu_xkfXnF1xg9E';
  //       getToken(messaging, { vapidKey: VAPID_KEY }) // Replace with your VAPID key
  //         .then((currentToken) => {
  //           if (currentToken) {
  //             console.log("FCM Token:", currentToken);
  //             // Send the token to your server or save it for later use
  //           } else {
  //             console.log("No registration token available.");
  //           }
  //         })
  //         .catch((err) => {
  //           console.error("An error occurred while retrieving token. ", err);
  //         });
  //     } else {
  //       console.log("Unable to get permission to notify.");
  //     }
  //   });

  //   // Handle incoming messages
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     // Customize notification here
  //     const notificationTitle = payload.notification.title;
  //     const notificationOptions = {
  //       body: payload.notification.body,
  //       icon: payload.notification.icon,
  //     };

  //     new Notification(notificationTitle, notificationOptions);
  //   });
  // }, []);
  // count
  return (
    // Show preloader while loading is true
    loading ? (
      <Preloader />
    ) : (
      // Router start
      <Router basename="/">
        <FCMProvider>
          <CartProvider>
            <ConditionalWrapper cartCount={cartCount}>
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />{" "}
                {/* Redirect root to /home */}
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/gift-cards" element={<Giftcard />} />
                <Route path="/HowItWorks" element={<HowItWorks />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/loginotp" element={<LoginOtp />} />
                <Route
                  path="/forgetpasswordotp"
                  element={<ForgetpasswordOtp />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/verification" element={<Verification />} />
                <Route path="/forgot" element={<Forgotpassword />} />
                <Route path="/newpassword" element={<Newpassword />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/writereview" element={<WriteReview />} />
                <Route path="/review" element={<Review />} />
                {/* <Route path="/PersonalDetail" element={<PersonalDetail />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/KycDetail" element={<KycDetail />} />
            <Route path="/AccountDetail" element={<AccountDetail />} />
            <Route path="/AddressDetail" element={<AddressDetail />} /> */}
                {/* <Route path="/notification" element={<Notification />} /> */}
                <Route path="/notification" element={<NotificationPage />} />
                <Route path="/Order" element={<OrderHistory />} />
                <Route path="/Orderdetail/:id" element={<MyOrderDetail />} />
                <Route path="/write-earn" element={<WriteEarn />} />
                <Route path="/getpost" element={<GetPost />} />
                <Route path="/offer" element={<AllOffers />} />
                <Route path="/balance" element={<Mybalance />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/policy" element={<PrivacyPolicy />} />
                <Route path="/term" element={<TermsAndConditions />} />
                <Route path="/myreview" element={<MyReview />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="/giftplus" element={<GiftPlus />} />
                <Route path="/productdetail/:sku" element={<ProductDetail />} />
                <Route path="/offerdetail/:id" element={<OfferDetail />} />
                <Route path="/viewall" element={<Viewall />} />
                <Route path="/categorydetail" element={<CategoryDetail />} />
                <Route path="/categorylist" element={<CategoryList />} />
                <Route
                  path="/categoryproduct/:id"
                  element={<ProductByCategory />}
                />
                <Route path="/mycard" element={<Mycard />} />
                <Route path="/addpluscard" element={<AddPlusCard />} />
                <Route
                  path="/rechargegifziplus"
                  element={<RechargeGifziPlus />}
                />
                <Route path="/blog" element={<AllBlog />} />
                <Route path="/blog" element={<AllBlog />} />
                <Route path="/blogdetail/:id" element={<BlogDetail />} />
                <Route path="/successpage" element={<Successfull_Page />} />
                <Route path="/failedpage" element={<Failed_Page />} />
                <Route path="/payu" element={<PayU />} />
                {/* <Route exact path="/payu" component={PayUComponent} /> */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </ConditionalWrapper>
          </CartProvider>
        </FCMProvider>
      </Router>
      // Router end
    )
  );
}

// Navbar wrapper
const ConditionalWrapper = ({ children, cartCount }) => {
  const location = useLocation();
  const hideNavbarFooterPages = [
    "/login",
    "/register",
    "/verification",
    "/forgot",
    "/newpassword",
    "/forgetpasswordotp",
    "/loginotp",
  ];

  const shouldHideNavbarFooter = hideNavbarFooterPages.includes(
    location.pathname
  );

  return (
    <>
      {!shouldHideNavbarFooter && <Navbar cartItemCount={cartCount} />}{" "}
      {/* Pass cartCount to Navbar */}
      <main>{children}</main>
      {!shouldHideNavbarFooter && <Footer />}
    </>
  );
};

export default App;
