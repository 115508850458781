import React from 'react'
import Banner from '../Components/HowItWorks/Banner/Banner'
import Process from '../Components/HowItWorks/Process/Process'
import User from '../Components/HowItWorks/User/User'
import Getstart from '../Components/HowItWorks/GetStart/Getstart'

function HowItWorks() {
  return (
    <div>
      <Banner/>
      <Process/>
      <User/>
      <Getstart/>
    </div>
  )
}

export default HowItWorks