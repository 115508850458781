// import React from 'react';
// import Commonbtn from '../Commonbtn/Commonbtn';
// import './Category.css';
// import catImg from "../AmazonImg/Kalyan.jpg";
// import { Link } from 'react-router-dom';

// function Category() {
//     const [category, setCategory] = React.useState([]);

//     const fetchProductData = async() => {
//         const baseUrl = `${process.env.REACT_APP_BASE_API_URL}home/homeData`;

//         try{
//             const fetchData = await fetch(baseUrl, {
//                 headers:{
//                     "Content-Type": "application/json",
//                     "Accept": "application/json"
//                 }
//             });

//             const jsonData = await fetchData.json();

//             if (jsonData && jsonData.status === 200) {
//                 const productJson = jsonData.data?.categories;
//                 console.log(productJson);
//                 setCategory(productJson);
//             }
//         } catch(err) {
//             console.log(err);
//         }
//     }

//     React.useEffect(() => {
//         fetchProductData();
//     }, []);

//     return (
//         <div className='Category-list p-80 text-center bg-fb'>
//             <div className='container'>
//                 <h3 className='title'>Categories</h3>
//                 <div className='row'>
//                     {Array.isArray(category) && category.map((item, index) => (
//                         <div key={index} className='col-md-4 gift-card' data-aos="flip-right" data-aos-delay="300">
//                             <Link 
//                                 to={{
//                                     pathname: `/categoryproduct/${item.id}`,
//                                     state: { categoryName: item?.name }
//                                 }}
//                                 className='card d-block p-3 mb-4 border-0'
//                             >
//                                    <h3 className='f-20 f-weight-600 mb-2'>
//                                     {item?.name}
//                                 </h3>
//                                 <i className='product-img'>
//                                     <img src={catImg} alt='category' height="100%" width="100%" />
//                                 </i>
                             
//                             </Link>
//                         </div>
//                     ))}

//                     <div className='col-md-12'>
//                         <Link to='/Viewall'>
//                             <Commonbtn Commonbtntext="SEE ALL" />
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Category;



// import React from 'react';
// import Commonbtn from '../Commonbtn/Commonbtn';
// import './Category.css';
// import catImg from "../AmazonImg/Kalyan.jpg";
// import { Link } from 'react-router-dom';

// function Category() {
//     const [category, setCategory] = React.useState([]);

//     const fetchProductData = async() => {
//         const baseUrl = `${process.env.REACT_APP_BASE_API_URL}home/homeData`;

//         try{
//             const fetchData = await fetch(baseUrl, {
//                 headers:{
//                     "Content-Type": "application/json",
//                     "Accept": "application/json"
//                 }
//             });

//             const jsonData = await fetchData.json();

//             if (jsonData && jsonData.status === 200) {
//                 const productJson = jsonData.data?.categories;
//                 console.log(productJson);
//                 setCategory(productJson);
//             }
//         } catch(err) {
//             console.log(err);
//         }
//     }

//     React.useEffect(() => {
//         fetchProductData();
//     }, []);

//     return (
//         <div className='Category-list p-80 text-center bg-fb'>
//             <div className='container'>
//                 <h3 className='title'>Categories</h3>
//                 <div className='row'>
//                     {Array.isArray(category) && category.map((item, index) => (
//                         <div key={index} className='col-md-4 gift-card' data-aos="flip-right" data-aos-delay="300">
//                             <Link 
//                                 to={{
//                                     pathname: `/categoryproduct/${item.id}`,
//                                     state: { categoryName: item?.name }
//                                 }}
//                                 className='card d-block p-3 mb-4 border-0'
//                             >
//                                    <h3 className='f-20 f-weight-600 mb-2'>
//                                     {item?.name}
//                                 </h3>
//                                 <i className='product-img'>
//                                     <img src={catImg} alt='category' height="100%" width="100%" />
//                                 </i>
                             
//                             </Link>
//                         </div>
//                     ))}

//                     <div className='col-md-12'>
//                         <Link to='/Viewall'>
//                             <Commonbtn Commonbtntext="SEE ALL" />
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Category;



import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import dinning from '../Assets/Category/dinning.svg';
import living from '../Assets/Category/living.svg';
import fashion from '../Assets/Category/fashion.svg';
import health from '../Assets/Category/health.svg';
import travel from '../Assets/Category/travel.svg';
import electronic from '../Assets/Category/electronic.svg';
import entertainment from '../Assets/Category/entertainment.svg';
import Gift from '../Assets/Category/gift1.svg';
import j from '../Assets/Category/j.svg';
import Commonbtn from '../Commonbtn/Commonbtn';

function Category() {
  const categories = {
    dining: { heading: 'Dining & Food', description: 'Explore dining options', img: dinning,heading2: 'Dining' },
    fashion: { heading: 'Fashion & Beauty', description: 'Latest fashion trends', img: fashion ,heading2: 'Fashion'},
    electronic: { heading: 'Electronics', description: 'Best electronics deals', img: electronic ,heading2: 'Electronics'},
    entertainment: { heading: 'Entertainment', description: 'Fun and entertainment', img: entertainment ,heading2: 'Entertainment'},
    health: { heading: 'Wellness & Health', description: 'Health and wellness products', img: health ,heading2: 'Wellness' },
    travel: { heading: 'Travel', description: 'Discover new destinations', img: travel ,heading2: 'Travel'},
    jewelry: { heading: 'Jewelry', description: 'Elegant jewelry collections', img: j ,heading2: 'Jewelry'},
    living: { heading: 'Home & Living', description: 'Home and living products', img: living ,heading2: 'Home'}
  };

  return (
    <div className='Category-list p-80 text-center bg-fb'>
      <div className='container'>
        <h3 className='title mb-4'>Categories</h3>
        <div className='row'>
          
          {Object.keys(categories).map((key, index) => (
            <div key={index} className='col-md-3 mb-4'>
              <Link 
                to={`/categoryproduct/121?name=${categories[key].heading2}`} 
                className='card p-3 border-0 text-center'
              >
                <div className='card-body'>
                  <img 
                    src={categories[key].img} 
                    alt={categories[key].heading} 
                    className="rounded mb-3" 
                    height="100px" 
                    width="100px" 
                  />
                  <h5 className='card-title f-14 f-weight-600'>{categories[key].heading}</h5>
                  {/* <p className='card-text'>{categories[key].description}</p> */}
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className='col-md-12 mt-5'>
          <Link to='/Viewall'>
            <Commonbtn Commonbtntext="SEE ALL" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Category;
