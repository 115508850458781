import React, { useEffect, useState } from 'react';
import Title from '../Title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import Commonbtn from '../Commonbtn/Commonbtn'; // Make sure to import Commonbtn
import treasure from '../Assets/treasure.svg';
import { Link } from 'react-router-dom';

function GetPost() {
  const [postData, setPostData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}menu/write_earn`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('authToken'), // If your API needs an auth token
          },
        });
        
        const result = await response.json();

        if (response.ok) {
          setPostData(result.data.contents || []); // Set to empty array if no contents
        } else {
          setErrorMessage(result.message || 'Error fetching data.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrorMessage('An error occurred while fetching data.');
      }
    };

    fetchData();
  }, []);

  return (
    <div className='getpost p-80'>
      <div className='container'>
        <Title title="Recent Post" />

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        {postData.length > 0 ? 
            
          
        
        (
          postData.map((content, index) => (
            <div key={index} className="post-content">
              <div className='card p-3 mb-3'>
                <h3 className='text-main f-18 f-weight-600 mb-2 text-uppercase'>{content.name}</h3>
                <div className='d-flex gap-2'>
                  <p className='text-muted f-16 text-grey mb-2'>
                    <FontAwesomeIcon icon={faPhone} className='text-grey f-16' /> {content.phone}
                  </p>
                  <p className='text-muted f-16 text-grey mb-2'> |</p>
                  <p className='text-muted f-16 text-grey mb-2'>
                    <FontAwesomeIcon icon={faMailBulk} className='text-grey f-16' /> {content.email}
                  </p>
                </div>
                <h4 className='text-main f-20 mb-1 f-weight-600'>URLs:</h4>
                <ul>
                  {content.urls.map((urlObj, idx) => {
                    const platform = Object.keys(urlObj)[0];
                    const url = Object.values(urlObj)[0].trim(); // Trim any spaces

                    return (
                      <li key={idx}>
                        {platform === 'instagram' || platform === 'twitter' || platform === 'facebook' ? (
                          <p>
                            <span className='text-capitalize f-16 f-weight-600'>
                              {platform}
                            </span>
                            <a href={`${url}`} target="_blank" rel="noopener noreferrer" className=''>
                              :  {url}
                            </a>
                          </p>
                        ) : (
                          `${platform}: ${url}`
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ))
        )
         : (
          <div>
              <div className='d-flex-center container  flex-column '>
             <p className='f-18 text-danger f-weight-600'>No data available.</p>
             <img src={treasure} className='treasure' height="200px" width="200px" alt="Treasure" />
             <Link to="/earn">
             <Commonbtn Commonbtntext="Post Now" />
             </Link>
           </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GetPost;
