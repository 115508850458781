import React, { useEffect, useState } from 'react';
import './Successfull_Page.css';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";

const Successfull_Page = () => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const location = useLocation();
  const [transactionId, setTransactionId] = useState(null);
  const [isGift, setIsGift] = useState(false);
  const [giftDetail, setGiftDetail] = useState({
    firstname: "",
    email: "",
    telephone: "",
    gift_message: "",
  });

  // Initialize `isGift` and `giftDetail` from localStorage
  useEffect(() => {
    const storedGiftDetail = localStorage.getItem("giftDetails");
    if (storedGiftDetail) {
      const parsedGiftDetail = JSON.parse(storedGiftDetail);

      // Check if any field in giftDetail is non-empty
      const hasGiftData = Object.values(parsedGiftDetail).some(value => value.trim() !== "");
      if (hasGiftData) {
        setGiftDetail(parsedGiftDetail);
        setIsGift(true);
      } else {
        setIsGift(false);
      }
    } else {
      setIsGift(false);
    }
  }, []); // Runs once on mount

  // API call for checkout
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('transcationid');
    setTransactionId(id);
  
    if (!id) return;
  
    const postCheckoutData = async () => {
      const apiUrl = `${baseUrl}payu/detail?txn_id=${id}`;
  
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('authToken')}`,
          },
        });
  
        if (response.ok) {
          const result = await response.json();
          let payment_data = result?.data?.payment_data;
          let productInfo = payment_data.productinfo;
  
          if (productInfo.includes("bothcard_")) {
            let udf2 = payment_data.udf2;
            let decodedData = decodeHTMLEntities(udf2);
            const cardData = JSON.parse(decodedData);
  
            // Check if giftDetails exist in localStorage
            const storedGiftDetails = localStorage.getItem("giftDetails");
            const parsedGiftDetails = storedGiftDetails ? JSON.parse(storedGiftDetails) : null;
  
            // Construct payload dynamically based on the existence of giftDetails
            const paymentDetails = {
              payment_mode: 0,
              status: 1,
              ...(parsedGiftDetails && {
                is_gift: 1,
                gift_detail: parsedGiftDetails,
              }),
              card_payment_detail: cardData.map((card) => ({
                id: card.id,
                link_id: card.link_id,
                amount: card.balance || 0,
              })),
            };
  
            // Send a single API call based on the constructed payload
            try {
              const apiResponse = await axios.post(
                `${process.env.REACT_APP_BASE_API_URL}order/checkout`,
                paymentDetails,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem('authToken'),
                  },
                }
              );
              localStorage.removeItem("giftDetails");
              if (apiResponse.status === 200) {
                console.log("Checkout successful");
  
                // Remove giftDetails from localStorage if the API call succeeds
                if (parsedGiftDetails) {
                  localStorage.removeItem("giftDetails");
                }
              } else {
                console.error("Error during checkout:", apiResponse.data);
              }
            } catch (error) {
              console.error("Payment Error:", error.response?.data || error.message);
            }
          }
  
          console.log('Checkout successful:', payment_data);
        } else {
          const errorData = await response.json();
          console.error('Error during checkout:', errorData.message);
        }
      } catch (error) {
        console.error('API call error:', error);
      }
    };
  
    postCheckoutData();
  }, [transactionId]);
  
   // Runs only when transactionId changes

  function decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  return (
    <div className='successfullpage'>
      <div className="js-container container">
        <div className="celebration-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark draw"></div>
          </div>
          <h1 className='mb-2 f-28 mt-3'>Congratulations!</h1>
          <p className='f-18 text-grey mb-4 '> You are all set. Well done!</p>
          <Link to="/gift-cards">
            <button className="common-btn">
              Back
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Successfull_Page;
