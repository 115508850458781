import React, { useEffect, useRef } from 'react';
import './Successfull_Page.css'
import { Link } from 'react-router-dom';

const Failed_Page = () => {
 

  return (
    <div className='successfullpage'>
 <div className="js-container container" >
      <div className="celebration-content">
      <i>
        
<svg width="100" height="100" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M81 151.875C120.143 151.875 151.875 120.143 151.875 81C151.875 41.8568 120.143 10.125 81 10.125C41.8568 10.125 10.125 41.8568 10.125 81C10.125 120.143 41.8568 151.875 81 151.875Z" stroke="#FF0909" stroke-width="4" stroke-miterlimit="10"/>
<path d="M81 45.5625V96.1875" stroke="#FF0909" stroke-width="4" stroke-miterlimit="10"/>
<path d="M81 106.312V116.438" stroke="#FF0909" stroke-width="4" stroke-miterlimit="10"/>
</svg>

      </i>
        <h1 className='text-danger f-30 mt-3'>Failed!</h1>
        <p>Please Try Again!</p>
        <Link to="/gift-cards">
        <button className="btn btn-danger mt-4">
          Back 
        </button>
          </Link>
    
      </div>
    </div>
    </div>
   
  );
};



export default Failed_Page;
