
import React from 'react';
import Slider from "react-slick";
import banner from '../Assets/plus/banner.png'
import banner2 from '../Assets/plus/banner2.png'

function PLusBanner() {
      // slider js 
  var plusslider = {
    dots: true,
    infinite: true,
    slidesToShow: 1.02,  
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  // slider js 
  return (
    <div className='banner p-30'>
    <div className='container'>
    <Slider {...plusslider}>
    <div>
        <div className='p-3'>

       <img src={banner} height="100%" width="100%" className="slider1" />
        </div>
       </div>
       <div>
        <div className='p-3'>

   <img src={banner2} height="100%" width="100%" className="slider1" />
        </div>
       </div>
      
       {/* <div>
       <img src={banner2} height="100%" width="100%" className="slider1" />
       </div> */}
   
     </Slider>
    </div>
     </div>
  )
}

export default PLusBanner